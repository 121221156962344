import { breakpoints } from 'src/styles/breakpoints';
import { white } from 'src/styles/colors';
import styled, { css, keyframes } from 'styled-components';

type ContainerProps = {
  isModal?: () => void;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
export const Container = styled.div<ContainerProps>`
  background-color: ${white};

  h3 {
    font-family: "Sora", Helvetica, sans-serif;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 100vh;
      padding: 50px 30px 40px;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 40px 100px;
      }
    `
  )}

  img {
    max-width: 100%;
  }

  .form--default {   
    label {
      color: #6A6C72;
      margin-bottom: 4px;
    }

    .form-label-check {    
      &::before {
        top: 15px;
      }   
    }
    .form-input-check {
      &:checked ~ label {
      &::after {
        top: 18px;     
        }
      }
    }
  }

  &.offers {
    @media (min-width: ${breakpoints.md}) {
      padding: 40px 20px;
    }
  }

  &.sent {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 60px;
      padding-right: 60px;
    }
    img {
      max-width: 185px;
    }
  }

  &.under-age {
    img {
      max-width: 204px;
    }
  }

  &.filled {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
`
